<template>
  <div class="my-reports-container">
    <MySavedReports :rows="rows" :loading="isDataLoading" :error="errorMessage" />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import moment from 'moment';
import appConsoleDataLayer from '@/api/app-console-data';
import MySavedReports from '@/pages/report-builder-v2/my-saved-reports.vue';

export default {
  name: 'MyReports',
  components: {
    MySavedReports,
  },
  data() {
    return {
      rows: [],
      isDataLoading: false,
      errorMessage: null, // Added error message state
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
  },
  async mounted() {
    await this.fetchUserSavedReports();
  },
  methods: {
    async fetchUserSavedReports() {
      const advertiserId = this.advertiser?.id;
      if (!advertiserId) {
        this.errorMessage = 'Advertiser ID is not available.';
        return;
      }

      this.isDataLoading = true;
      this.errorMessage = null; // Reset error message
      try {
        const data = await appConsoleDataLayer.getUserSavedReports(advertiserId);
        this.rows = this.processReportsData(data);
      } catch (error) {
        console.error('Error fetching templates:', error);
        this.errorMessage = 'Failed to load saved reports. Please try again later.'; // Set user-friendly error message
      } finally {
        this.isDataLoading = false;
      }
    },
    processReportsData(data) {
      if (!data || data.length === 0) return [];

      return data
        .map((item) => ({
          ...item,
          createdAt: moment(item.createdAt).format('MM-DD-YYYY HH:mm:ss'),
          dateRange: this.getDataRangeValues(item.filters),
        }))
        .sort((a, b) => b.id - a.id); // Sort by id in descending order
    },
    getDataRangeValues(filters) {
      if (filters.dateRangeType === 'CUSTOM') {
        return `${moment(filters.startDate).format('MM-DD-YYYY')} - ${moment(
          filters.endDate
        ).format('MM-DD-YYYY')}`;
      }

      const dateRangeMap = {
        LAST_7_DAYS: 'Last 7 Days',
        LAST_2_WEEKS: 'Last 2 Weeks',
        LAST_30_DAYS: 'Last 30 Days',
        CAMPAIGN_TO_DATE: 'Campaign To Date',
      };

      return dateRangeMap[filters.dateRangeType] || filters.dateRangeType;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-reports-container {
  margin-top: 20px;
}
</style>
