<template>
  <div class="table-wrap no-wrap-css">
    <!-- Success and Error Message Slot -->
    <div v-if="showMessage" :class="['message', messageType]" class="message-container">
      <slot name="message">{{ message }}</slot>
    </div>

    <div ref="table-scroll" class="table-scroll my-saved-reports-container">
      <div v-if="!loading">
        <template v-if="rows.length">
          <table class="tbl tbl-export" :class="css">
            <thead>
              <tr>
                <th
                  v-if="rowsCollapsible"
                  class="short-column sticky-column-header subRowCollapsible"
                ></th>
                <th
                  v-for="(header, i) in headers"
                  :key="i"
                  :ref="`${header.name}_${i}`"
                  :class="['headers', { 'sticky-column sticky-column-header': header.isSticky }]"
                  @mouseover="(el) => handleMouseOver(el, header.name, `${header.name}_${i}`)"
                  @mouseleave="handleMouseLeave"
                >
                  {{ header.name }}
                </th>
              </tr>
            </thead>
            <tbody class="body-half-screen">
              <tr
                v-for="(item, i) in rows"
                :id="i"
                :key="i"
                :class="[{ 'sub-row': !!item.subRow }, { highlightBackgroundParent: item.open }]"
              >
                <td
                  :ref="`row_name_${i}`"
                  class="saved-reports-cell"
                  @mouseover="handleMouseOver($event, item.name, `row_name_${i}`)"
                  @mouseleave="handleMouseLeave"
                >
                  <div class="tooltip-container">{{ item.name }}</div>
                </td>
                <td
                  :ref="`row_dateRange_${i}`"
                  @mouseover="handleMouseOver($event, item.dateRange, `row_dateRange_${i}`)"
                  @mouseleave="handleMouseLeave"
                >
                  <div class="tooltip-container">{{ item.dateRange }}</div>
                </td>
                <td
                  :ref="`row_createdAt_${i}`"
                  @mouseover="handleMouseOver($event, item.createdAt, `row_createdAt_${i}`)"
                  @mouseleave="handleMouseLeave"
                >
                  <div class="tooltip-container">{{ item.createdAt }}</div>
                </td>
                <td>
                  <div class="action-icons">
                    <div v-if="downloading[item.id]" class="ftop-icons fltr-btn tt">
                      <font-awesome-icon class="action-icon" :icon="['fas', 'circle-notch']" spin />
                    </div>
                    <div
                      v-else
                      :ref="`download_report_${i}`"
                      class="ftop-icons fltr-btn tt"
                      @click="downloadReport(item)"
                      @mouseover="
                        handleMouseOver($event, 'Download Report', `download_report_${i}`)
                      "
                      @mouseleave="handleMouseLeave"
                    >
                      <font-awesome-icon class="action-icon" :icon="['fas', 'arrow-to-bottom']" />
                    </div>
                    <div
                      :ref="`view_report_${i}`"
                      class="ftop-icons fltr-btn tt"
                      @click="viewReport(item)"
                      @mouseover="handleMouseOver($event, 'View Report', `view_report_${i}`)"
                      @mouseleave="handleMouseLeave"
                    >
                      <font-awesome-icon class="action-icon" :icon="['fas', 'eye']" />
                    </div>
                    <div
                      :ref="`delete_report_${i}`"
                      class="ftop-icons fltr-btn tt"
                      @click="showDeleteConfirmationPopup(item)"
                      @mouseover="handleMouseOver($event, 'Delete Report', `delete_report_${i}`)"
                      @mouseleave="handleMouseLeave"
                    >
                      <font-awesome-icon class="action-icon" :icon="['fas', 'trash']" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <div v-else class="txt-center" style="margin-top: 13px;">
          No data is available to show
        </div>
      </div>
      <b-loading-spinner v-if="loading" class="txt-center" />
    </div>
    <div :style="activeEl.style" class="tooltipText">{{ activeEl.text }}</div>

    <div v-if="showDeleteTemplatePopup.visible" class="show-delete-template-popup">
      <SavedReportConfirmModal
        :description="showDeleteTemplatePopup.description"
        :loading="isDeleteLoading"
        @cancel="onCloseDeletePopup"
        @change="onDeleteTemplatePopup"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import config from '@/config';
import axios from 'axios';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import fileDownload from 'js-file-download';
import moment from 'moment';
import SavedReportConfirmModal from './saved-report-confirm-modal.vue';

const headers = [
  { name: 'Saved Reports' },
  { name: 'Date Range' },
  { name: 'Created' },
  { name: 'Actions' },
];

export default {
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    SavedReportConfirmModal,
  },
  props: {
    rows: { type: Array, default: () => [] },
    headers: { type: Array, default: () => headers },
    rowToScrollTo: { type: String, default: '' },
    rowsCollapsible: { type: Boolean, default: false },
    css: { type: String, default: '' },
    isPageView: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    error: { type: String, default: '' },
  },
  data() {
    return {
      activeEl: { style: { display: 'none' }, text: '' },
      showMessage: false,
      message: '',
      messageType: 'success',
      showDeleteTemplatePopup: { visible: false, payload: {} },
      isDeleteLoading: false,
      downloading: {},
    };
  },
  computed: {
    incReachMetricsData: get('dashboard/incReachMetricsData'),
  },
  watch: {
    error(newValue) {
      if (newValue) {
        this.showErrorMessage(newValue); // Show the error message if it changes
      }
    },
  },
  mounted() {
    this.scrollToRow(this.rowToScrollTo);
  },
  methods: {
    handleMouseOver(el, text, refKey, isRow = false) {
      const elementPos = this.$refs[refKey][0];
      const tableElement = this.$refs['table-scroll'];
      const addScrollTop = isRow && !this.isPageView ? 170 : 35;

      if (!elementPos) return;

      this.activeEl = {
        style: {
          top: `${elementPos.offsetParent.offsetTop - tableElement.scrollTop + addScrollTop}px`,
          left: `${elementPos.offsetLeft - tableElement.scrollLeft + 40}px`,
          display: 'block',
        },
        text,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    scrollToRow(rowId) {
      const row = this.$refs['table-scroll'].querySelector(`table tbody tr[id="${rowId}"]`);
      if (row) this.$refs['table-scroll'].scrollTo(0, row.offsetTop - row.offsetHeight - 5);
    },
    genCSVFileName(item) {
      return `${item.name}-${moment(new Date()).format('yyyy-MM-DD')}.csv`;
    },
    saveCSV(data, fileName = 'file.csv') {
      fileDownload(data, fileName);
    },
    downloadReport(item) {
      this.$set(this.downloading, item.id, true);
      const payload = {
        id: item.id,
        export: true,
      };
      const url = `${config.ADREADY_URL}/api/ac/advertisers/${item.advertiserId}/reportTemplate/run`;
      const apiCall = axios.post(url, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv',
        },
        responseType: 'blob',
      });
      console.log('api call download called');
      apiCall
        .then((response) => {
          console.log('response', response);
          if (response?.status === 200) {
            console.log('download success');
            this.saveCSV(response.data, this.genCSVFileName(item));
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'An unknown error occurred';
          console.error('Error:', errorMessage);
          this.showErrorMessage(errorMessage);
        })
        .finally(() => {
          this.$set(this.downloading, item.id, false); // Reset downloading state for this row
        });
    },
    viewReport(item) {
      const payload = {
        id: item.id,
        advertiserId: item.advertiserId,
      };
      EventBus.$emit('switch-to-tab-index', 2);
      this.triggerSearch(payload);
    },
    triggerSearch(payload) {
      if (EventBus.myReportsListenerReady) {
        // If the listener is ready, emit the event
        EventBus.$emit('do-new-report-search-from-my-reports', payload);
      } else {
        // If the listener isn't ready, retry after a short delay
        setTimeout(() => this.triggerSearch(payload), 200); // Retry after 200ms
      }
    },
    async deleteReport(item) {
      const url = `${config.ADREADY_URL}/api/ac/advertisers/${item.advertiserId}/reportTemplate/${item.id}`;
      try {
        const response = await axios.delete(url, { withCredentials: true });
        if (response.data.status === 200) {
          this.showSuccessMessage(`${item.name} has been deleted.`);
          const index = this.rows.indexOf(item);
          if (index > -1) this.rows.splice(index, 1);
        }
      } catch (error) {
        console.error(`error processing report ->`, error);
        this.showErrorMessage(
          `Encountered an error deleting ${item.name}. Please contact administrator.`
        );
      } finally {
        this.$nextTick(() => {
          this.isDeleteLoading = false;
          this.onCloseDeletePopup();
        });
      }
    },
    showSuccessMessage(msg) {
      this.message = msg;
      this.messageType = 'success';
      this.displayMessage();
    },
    showErrorMessage(msg) {
      this.message = msg;
      this.messageType = 'error';
      this.displayMessage();
    },
    displayMessage() {
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 5000);
    },
    showDeleteConfirmationPopup(item) {
      this.showDeleteTemplatePopup = {
        visible: true,
        description: `Delete template ${item.name}?`,
        payload: {
          item,
        },
      };
    },
    onCloseDeletePopup() {
      this.showDeleteTemplatePopup = { visible: false, payload: {} };
    },
    onDeleteTemplatePopup() {
      this.isDeleteLoading = true;
      this.$nextTick(() => {
        const { payload } = this.showDeleteTemplatePopup;
        this.deleteReport(payload.item);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  * {
    box-sizing: border-box;
  }
  .table-wrap {
    .tbl {
      border-collapse: separate;
      thead {
        z-index: 4;
        background-color: var(--primarycolor) !important;
      }
      tr td {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
    }
    .table-scroll {
      width: 100%;
      max-height: 36.5rem !important;
      overflow: auto;
    }
    .message-container {
      margin-bottom: 10px;
      padding: 10px;
      text-align: center;
      border-radius: 4px;
    }
    .message.success {
      background-color: #d4edda;
      color: #155724;
      border: 1px solid #c3e6cb;
    }
    .message.error {
      background-color: #f8d7da;
      color: #721c24;
      border: 1px solid #f5c6cb;
    }
  }
  table.tbl-export {
    & thead {
      padding-bottom: unset;
      border-bottom: unset;
    }
    & td.short-column.sticky-column.subRowCollapsible {
      width: 26px !important;
      overflow: unset;
    }
    & th.short-column.sticky-column-header.subRowCollapsible {
      width: 26px !important;
    }
    .sticky-column {
      z-index: 1;
      background-color: #212 429;
      &.subRowCollapsible {
        left: 0px;
      }
      &.sub-row-column-highlight {
        background-color: #191919;
      }
      &-header {
        background: var(--primarycolor);
        z-index: 5;
        &.subRowCollapsible {
          left: 0px;
        }
      }
    }
  }
  .body-half-screen {
    .relative {
      position: relative;
    }
    tr {
      position: relative;
    }
    tr td.highlightBorder {
      border-right: 2px solid var(--primarycolor);
      left: 1px;
      z-index: 3;
      height: 3em;
    }
  }
  .tooltipText {
    position: absolute;
    z-index: 999;
    width: auto;
    font-size: 12px;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
    text-align: center;
    border-radius: 3px;
    transition: ease 0.3s;
    line-height: 1.2em;
    transform-origin: bottom center;
    padding: 5px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .highlightBackgroundParent {
    background-color: #191919;
    border-bottom: none;
    > .sticky-column {
      background-color: #191919;
      &:nth-child(n + 2):nth-child(-n + 5) {
        background-color: #191919;
        &:hover {
          background-color: #373a44;
        }
      }
      &:hover {
        background-color: #373a44;
      }
    }
    &:hover {
      background-color: #373a44;
    }
  }
  .my-saved-reports-container {
    td {
      word-break: break-all;
    }
    .action-icon {
      margin-right: 10px;
    }
    .headers {
      color: white;
    }
    th {
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 20%;
      }
    }
    tr td {
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 20%;
      }
    }
    .action-icon {
      cursor: pointer;
    }
  }
  .txt-center {
    text-align: center;
    width: 100%;
  }
  .ftop-icons {
    position: relative;
    display: inline-block;
    vertical-align: top;
    color: var(--primarydark2);
    cursor: pointer;
    font-size: 16px;
    margin-right: 3px;
    margin-left: 4px;

    .ttip-wrap {
      position: absolute;
      width: max-content;
      text-align: center;
      transform: translate3d(0px, 0px, 0px);
      padding-top: 0px;
      display: inline-block;
      visibility: hidden;
      opacity: 0;
      font-size: 14px;
      transition: all 0.2s ease;
      z-index: 103;
      pointer-events: none;
      left: -47px;
      top: 20px;

      .ttip {
        display: inline-block;
        position: relative;
        padding: 4px 8px;
        border-radius: 6px;
        background-color: var(--primarydark2);
        color: #fff;
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;
        line-height: 1.1em;
        margin-top: 0px;
      }
      .ttip::before {
        content: '';
        display: inline-block;
        left: 50%;
        top: -5px;
        height: 0px;
        width: 0px;
        border-right: solid 6px transparent;
        border-left: solid 6px transparent;
        border-bottom: solid 6px var(--primarydark2);
        position: absolute;
        margin-left: -3px;
      }
    }
    &:hover .fa-filter {
      color: var(--primarycolor);
    }
    &:hover .ttip-wrap {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0px, 6px, 0px);
    }
  }
}
.saved-reports-cell {
  word-wrap: break-word; /* Allow long words to be broken and wrap onto the next line */
  overflow: hidden; /* Hide overflow content */
  white-space: normal; /* Allow text to wrap */
}
.show-delete-template-popup {
}
</style>
