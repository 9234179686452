<template>
  <div class="rpt_main_wrap">
    <div class="rpt_panel def-box expan">
      <div class="tabs-container">
        <!-- Tabs Navigation -->
        <div class="tabs">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            class="tab"
            :class="{ active: isActiveTab(index), disabled: isTabDisabled(index) }"
            @click="handleTabClick(index)"
          >
            {{ tab.name }}
            <div v-if="isActiveTab(index)" class="active-line"></div>
          </div>

          <div v-if="isActiveTab(2)" class="flp-controls">
            <div class="fright">
              <k-download-btn
                :preparing="downloadBtn.preparing"
                :done="downloadBtn.done"
                @click.native="download()"
                @click.stop
              />
              <b-email-btn
                :email-status="emailSentStatus"
                :external-open="openEmailPopup"
                @email-to="emailReport"
              />
            </div>
          </div>
        </div>

        <!-- Tabs Content -->
        <div class="tab-content">
          <component
            :is="currentComponent"
            ref="viewReportComponent"
            :active-solution-id="activeSolutionId"
            @update-download-status="updateDownloadStatus"
            @update-email-sent-status="updateEmailSentStatus"
            @update-open-email-popup="updateOpenEmailPopup"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/adready-vue/helpers/global/event-bus';
import ReportsBuilder from './reports-builder.vue';
import MyReports from './my-reports.vue';
import ViewReport from './view-report.vue';

export default {
  name: 'ReportsBuilder',
  components: {
    'reports-builder': ReportsBuilder,
    'my-reports': MyReports,
    'view-report': ViewReport,
    KDownloadBtn: () =>
      import(/* webpackChunkName: "k-download-btn" */ '~/components/elements/k-download-btn.vue'),
    BEmailBtn: () =>
      import(/* webpackChunkName: "b-email-btn" */ '~/components/elements/b-email-btn.vue'),
  },
  props: {
    activeSolutionId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tabs: [
        { name: 'Report Builder', component: 'reports-builder' },
        { name: 'My Reports', component: 'my-reports' },
        { name: 'View Report', component: 'view-report' },
      ],
      activeTab: 1, // Default active tab index - My Reports
      disabledTabs: [2], // Initially disable tab at index 2
      downloadBtn: {
        preparing: false,
        done: false,
      },
      openEmailPopup: false,
      emailSentStatus: '',
    };
  },
  computed: {
    currentComponent() {
      return this.tabs[this.activeTab].component;
    },
    isActiveTab() {
      return (index) => this.activeTab === index;
    },
  },
  created() {
    EventBus.$on('switch-to-tab-index', this.handleTabSwitchEvent);
  },
  beforeDestroy() {
    EventBus.$off('switch-to-tab-index', this.handleTabSwitchEvent);
  },
  methods: {
    handleTabClick(index) {
      if (!this.isTabDisabled(index)) {
        this.setActiveTab(index);
      }
    },
    setActiveTab(index) {
      this.activeTab = index;
      if (index !== 2 && !this.disabledTabs.includes(2)) {
        this.disabledTabs.push(2);
      }
    },
    handleTabSwitchEvent(index) {
      this.setActiveTab(index);
      this.enableTab(index);
    },
    isTabDisabled(index) {
      return this.disabledTabs.includes(index);
    },
    enableTab(index) {
      const tabIndex = this.disabledTabs.indexOf(index);
      if (tabIndex !== -1) {
        this.disabledTabs.splice(tabIndex, 1);
      }
    },
    download() {
      this.downloadBtn.preparing = true;
      if (this.currentComponent === 'view-report') {
        this.$refs.viewReportComponent.download(); // Call method in the View-report-component
      }
    },
    updateDownloadStatus(status) {
      this.downloadBtn.done = status.done;
      setTimeout(() => {
        this.downloadBtn.preparing = status.preparing;
        this.downloadBtn.done = status.done;
      }, 1000);
    },
    emailReport(email = '') {
      this.openEmailPopup = true;
      if (this.currentComponent === 'view-report') {
        this.$refs.viewReportComponent.emailReport(email); // Call method in the View-report-component
      }
    },
    updateOpenEmailPopup(status) {
      this.openEmailPopup = status.openEmailPopup;
    },
    updateEmailSentStatus(status) {
      this.emailSentStatus = status.emailSentStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.rpt_main_wrap {
  transition: all 0.3s ease;
  margin-top: -40px;
}

.rpt_panel {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 40, 60, 0.07), 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgba(165, 173, 178, 0.22);
}

@media (max-width: 900px) {
  .rpt_panel.two-thirds,
  .rpt_panel.one-thirds {
    width: 100% !important;
  }
}

.rpt_panel.two-thirds {
  width: calc(65% - 15px);
  display: inline-block;
}

.rpt_panel.one-thirds {
  width: calc(35% - 15px);
  display: inline-block;
}

.rpt_panel.last {
  float: right;
}

.rpt_panel .dropdown {
  border: 1px solid #c8d3e4;
}

.def-box {
  padding: 10px;
  max-height: 1070px;
  z-index: 10;
  position: relative;
  min-height: 170px;
}

.expan {
  &.expd {
    height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
  }
}

.tabs-container {
  font-family: 'Source Sans Pro', sans-serif;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
  align-items: center;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  color: #555;
  font-size: 16px;
  position: relative;
  transition: color 0.3s ease;
}

.tab:hover {
  color: #333;
}

.tab.active {
  color: #000;
  font-weight: bold;
}

.active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primarycolor);
}

.tab.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.fright {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flp-controls {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;
}

::v-deep .btn-size2 {
  padding: 10px;
}

::v-deep .k-btn.btn-size-4 {
  background-color: var(--primarycolor) !important;
  color: var(--buttontext) !important;
}
</style>
