<template>
  <div>
    <!-- Success and Error Message Slot -->
    <div v-if="showMessage" :class="['message', messageType]" class="message-container">
      <slot name="message">{{ message }}</slot>
    </div>
    <div class="grid-container">
      <!-- Column 1: Template, Date Range, Media, and Interval -->
      <div class="grid-item" :class="{ disabled: isApiCallsLoading }">
        <div class="section">
          <div class="filter">
            <div class="filter-ttl"><span>TEMPLATE</span></div>
            <div class="filter-group">
              <div class="flp-controls">
                <b-loading-spinner2 v-if="isApiCallsLoading" :width="20" />
                <b-select
                  v-else
                  class="b-select"
                  :options="filters.templateOptions"
                  :selected="filters.template"
                  @change="onSelectTemplate"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="filter">
            <div class="filter-ttl"><span>DATE RANGE</span></div>
            <div class="filter-group">
              <div class="pan-content-top pan1-content-top">
                <b-datepicker
                  :key="dateKey"
                  :week1="[dates.startDate, dates.endDate]"
                  :campaign-options="campaignOptions"
                  :active-solution-id="activeSolutionId"
                  :hide-compare-with="true"
                  :on-reports-builder="true"
                  @change="bDatepickerChanges"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="filter media-settings">
            <div class="filter-ttl">
              <span>MEDIA</span>
            </div>
            <div class="filter-group mediaFilters">
              <div class="campaign-opts">
                <dropdown-checkbox-search
                  key="campaign"
                  name="campaign"
                  label="Search..."
                  :options="filters.campaignOptions"
                  :fancy="true"
                  :multiple="true"
                  :campaign-options-changed="campaignOptionsChanged"
                  label-key="value"
                  title=""
                  header="Campaigns"
                  component-id="ioOptionDropdown"
                  :allow-multi-select="true"
                  :show-all="false"
                  :value="selectedFilters.campaignOptions ? selectedFilters.campaignOptions : []"
                  original-component="dropdown"
                  @input="(val) => setSelectedFilters('campaignOptions', val)"
                />
              </div>
              <b-select-checkbox
                :options="filters?.adGroupOptions || adGroupOptions"
                title=""
                header="Ad Group"
                class="ad-group-select"
                :disabled="true"
                :show-all="filters?.adGroupOptions?.length > 1 || adGroupOptions.length > 1"
              />
              <div class="fp-text-link view-pop-fltr" :class="{ disabled: isAdGroupLoading }">
                <p @click="toggleAdvanceFilterModal">Advanced Filters</p>
                <span
                  >{{ appliedFilterCount > 0 ? `(${appliedFilterCount} Applied)` : '' }}
                  <font-awesome-icon
                    v-if="!isAdGroupLoading"
                    :icon="['far', 'times']"
                    class="advance-filter-clear-icon"
                    @click="onClearAdvanceFilters"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="filter aggregation">
            <div class="filter-ttl">
              <span>INTERVAL</span>
            </div>
            <div class="filter-group">
              <b-select
                class="aggregation-type"
                :options="filters.intervalOptions"
                :selected="filters.interval"
                :disabled="true"
                @change="onSelectInterval"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Column 2: Attribution Settings, Methodology, Conversion Window -->
      <div class="grid-item">
        <div class="section">
          <div class="filter method-settings">
            <div class="filter-ttl"><span>ATTRIBUTION SETTINGS</span></div>
            <div class="filter-group">
              <b-radio-tab-group
                :initial-group="filters.totalOrUniqueRadioOptions || totalOrUniqueRadioOptions"
                :is-mni-account="isMniAccount"
                class="tot-uniq"
              />
            </div>
            <div class="filter-ttl"><span>Methodology</span></div>
            <div class="filter-group">
              <b-select
                class="media-select"
                :options="filters.methodologyOptions"
                :selected="filters.methodology"
                :disabled="true"
                @change="onSelectMethodology"
              />
              <div class="conv-content">
                <div class="filter-ttl"><span>Lookback Window</span></div>
                <div class="conversion-opt-cont">
                  <div class="opt-b1" data-children-count="1">
                    <input
                      v-model="filters.conversionWindowDisplay"
                      type="text"
                      name="conversion-window"
                      class="flp-input input-conv"
                      @keypress="onlyNumber"
                      @input="validateConversionWindow"
                      @blur="handleBlur"
                    />
                  </div>
                  <div class="opt-b2">
                    <b-checkbox
                      label="Days"
                      name="Days"
                      icon="circle"
                      :is-radio="true"
                      :checked="filters.conversionWindowUnit === 'DAYS'"
                      @change="updateConversionWindowUnit"
                    />
                    <b-checkbox
                      label="Hours"
                      name="Hours"
                      icon="circle"
                      :is-radio="true"
                      :checked="filters.conversionWindowUnit === 'HOURS'"
                      @change="updateConversionWindowUnit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Column 3: Dimensions -->
      <div class="grid-item">
        <div class="section">
          <div class="filter checkbox-select">
            <div class="filter-ttl">
              <span>DIMENSIONS</span>
            </div>
            <!-- Dynamic Options -->
            <div
              v-for="option in reportDimensionsOptions"
              :key="option.id"
              :class="{
                disabled: disableAllDimensions,
              }"
              class="checkbox-select__check-wrapp dimension-item"
            >
              <input
                :id="option.id"
                v-model="selectedDimensionsOptions"
                class="conditions-check"
                type="checkbox"
                :value="option.id"
                :disabled="disableAllDimensions"
                @change="handleDimensionChange(option.id)"
              />
              <label :for="option.id">{{ option.name }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Column 4: Metrics -->
      <div class="grid-item metrics-opts">
        <div class="section">
          <div class="filter checkbox-select">
            <div class="filter-ttl"><span>METRICS</span></div>
            <!-- SELECT ALL Option -->
            <div
              class="checkbox-select__check-wrapp dimension-item"
              :class="{ disabled: disabledAllMetrics }"
            >
              <input
                id="select-all-metrics"
                v-model="selectAllMetrics"
                type="checkbox"
                class="conditions-check"
                :disabled="disabledAllMetrics"
                @change="toggleSelectAllMetrics"
              />
              <label for="select-all-metrics">SELECT ALL</label>
            </div>
            <!-- Dynamic Options -->
            <div
              v-for="option in reportMetricsOptions"
              :key="option.key"
              :class="{ disabled: disabledAllMetrics }"
              class="checkbox-select__check-wrapp dimension-item"
            >
              <input
                :id="option.key"
                v-model="selectedMetricsOptions"
                type="checkbox"
                class="conditions-check"
                :value="option.key"
                :disabled="disabledAllMetrics"
                @change="handleMetricsChange(option.key)"
              />
              <label :for="option.key">{{
                option.value === 'Checkout Conversion Rate'
                  ? 'Conversions Conversion Rate'
                  : option.value === 'Checkout CPA'
                  ? 'Conversions CPA'
                  : option.value === 'Checkout Performance Multiplier'
                  ? 'Conversions Performance Multiplier'
                  : option.value
              }}</label>
            </div>
            <hr />
            <div
              v-for="(event, index) in eventOptions"
              :key="`event-${index}`"
              :class="{
                disabled:
                  disabledAllEvents ||
                  (['Clicks', 'Completions', 'Video Completions'].includes(event.event) &&
                    ['all_touch', 'last_touch'].includes(filters.template)),
              }"
              class="checkbox-select__check-wrapp dimension-item"
            >
              <!-- Main Event Checkbox -->
              <input
                :id="`event-${index}`"
                v-model="event.selected"
                type="checkbox"
                class="conditions-check"
                :value="event.category"
                :disabled="
                  (hasOnlySingleEventSelected && event.selected) ||
                    disabledAllEvents ||
                    (['Clicks', 'Completions'].includes(event.event) &&
                      ['all_touch', 'last_touch'].includes(filters.template))
                "
                :readonly="singleSelectMode && event.selected"
                @change="toggleMainCategory(index)"
              />
              <label :for="`event-${index}`">
                {{
                  event.event === 'Video Completions'
                    ? 'Completions'
                    : event.event === 'Checkouts'
                    ? 'Conversions'
                    : event.event
                }}
                <span v-if="event.numSubCategories"
                  >({{ event.numSubCategories }} Subcategories)</span
                >
              </label>

              <!-- Subcategories -->
              <div
                v-if="event.selected && event.children && event.children.length"
                class="subcategory-container"
              >
                <div
                  v-for="(subCategory, subIndex) in event.children"
                  :key="`event-${index}-subCategory-${subIndex}`"
                  class="checkbox-select__check-wrapp dimension-item subcategory-item"
                >
                  <input
                    :id="`event-${index}-subCategory-${subIndex}`"
                    v-model="subCategory.selected"
                    type="checkbox"
                    class="conditions-check"
                    :value="subCategory.category"
                    @change="resetSubCategories(subIndex)"
                  />
                  <label :for="`event-${index}-subCategory-${subIndex}`">{{
                    subCategory.event
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Column 5: Delivery -->
      <DeliveryGrid
        :run-now="runNow"
        :save-template="saveTemplate"
        :report-name="reportName"
        :is-valid-report-name="isValidReportName"
        :button-label="buttonLabel"
        :disable-save-report-template="disableSaveReportTemplate"
        @run-now-changed="handleRunNowChange"
        @save-template-changed="handleSaveTemplateChange"
        @report-name-changed="trimReportName"
        @run-or-save-report="runOrSaveReport"
      />
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import fetchIosMethodMixin from '@/components/mixins/fetch-ios-method-mixin';
import fetchCampaignsMethodMixin from '@/components/mixins/fetch-campaigns-method-mixin';
import fetchEventsMethodMixin from '@/components/mixins/fetch-events-method-mixin';
import gaEventsMixin from '@/components/mixins/ga-events-mixin';
import BDatepicker from '@/components/elements/b-datepicker.vue';
import BSelectCheckbox from '@/components/elements/b-select-checkbox.vue';
import BSelect from '@/components/elements/b-select.vue';
import BRadioTabGroup from '@/components/elements/b-radio-tab-group.vue';
import BCheckbox from '@/components/elements/b-checkbox.vue';
import DropdownCheckboxSearch from '@/components/elements/b-auto-complete-dropdown3.vue';
import {
  ACCOUNTS_TO_SHOW,
  MNI_ACCOUNT_ID,
  MNI_DEMO_ACCOUNT_ID,
  REPORT_BUILDER_DISABLED_METRICS,
  REPORT_BUILDER_EXTRA_METRICS,
  REPORT_BUILDER_METRIC_OPTIONS,
  NEW_REPORT_OPTIONS,
} from '@/constant';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import {
  convertEpochToNYTimezone,
  generateFlipDefaultDates,
  getCampaignDateRange,
  offsetDate,
} from '@/util/utility-functions';
import axios from 'axios';
import appConsoleDataLayer from '@/api/app-console-data';
import bLoadingSpinner2 from '@/components/elements/b-loading-spinner2.vue';
import { formatDateForAPI } from '@/util/apiDateFormat';
import config from '~/config';
// import FilterColumnGrid from './filter-column-grid.vue';
// import AttributionSettingsGrid from './attribution-settings-grid.vue';
// import DimensionsGrid from './dimensions-grid.vue';
// import MetricsGrid from './metrics-grid.vue';
import DeliveryGrid from './delivery-grid.vue';

export default {
  name: 'ReportBuilder',
  components: {
    BDatepicker,
    BSelectCheckbox,
    BSelect,
    BRadioTabGroup,
    BCheckbox,
    DropdownCheckboxSearch,
    // FilterColumnGrid,
    // AttributionSettingsGrid,
    // DimensionsGrid,
    // MetricsGrid,
    DeliveryGrid,
    bLoadingSpinner2,
  },
  mixins: [fetchIosMethodMixin, fetchCampaignsMethodMixin, fetchEventsMethodMixin, gaEventsMixin],
  props: {
    activeSolutionId: {
      type: Number,
      required: false,
      default: 8, // mni
    },
  },
  data() {
    const filterOptionsArray = [
      'audienceOptions',
      'creativeOptions',
      'adGroupOptions',
      'mediaTypeOptions',
      'campaignOptions',
      'ioOptions',
      'keywordOptions',
      'publisherOptions',
    ];
    return {
      config,
      filters: this.genFilters(),
      initialFilters: {},
      isAdGroupLoading: false,

      reportDimensionsOptions: [], // To store the fetched report options
      selectedDimensionsOptions: ['creative'], // Tracks selected options
      disableAllDimensions: false,
      // selectAllDimensions: false, // Tracks "SELECT ALL" checkbox state

      reportMetricsOptions: [],
      selectedMetricsOptions: [], // Tracks selected options
      disabledAllMetrics: false,
      selectAllMetrics: false, // Tracks "SELECT ALL" checkbox state
      disabledAllEvents: false,
      singleSelectMode: false,

      selectedReportType: 'performance',
      selectedReportKind: 'creative',
      allEventPerformance: false,

      runNow: true, // Default to "Run now" being checked
      saveTemplate: true, // Default to "Save as report template" being unchecked
      reportName: '',
      buttonLabel: 'RUN REPORT', // Default button label
      showTooltip: false, // Controls visibility of the tooltip
      disableSaveReportTemplate: false,

      exportResults: false,
      scheduleReport: true,
      reportFrequency: 'Monthly',
      emailRecipients: '',

      campaignOptionsChanged: false,
      selectedFilters: this.getSelectedFilters(this.genFilters(), filterOptionsArray),

      dateKey: 0,
      isAllOrLastTouch: false,

      isInitialCampaignChange: true,
      isInitialFilterChange: true,
      isInitialConversionWindowChange: true,
      isInitialConversionUnitChange: true,

      isApiCallsLoading: false,
      startTime: null, // Store the time when the first retry is made
      retryTimeout: 20000, // 20 seconds in milliseconds

      showMessage: false,
      message: '',
      messageType: 'success',

      selectedTemplateId: '',
    };
  },
  computed: {
    account: get('common/account'),
    dates: get('dashboard/dates'),
    isMniAccount: get('common/isMniAccount'),
    mniTemplateId: get('common/mniTemplateId'),
    isViaAccount: get('common/isViaAccount'),
    eventOptions: get('dashboard/filters@eventOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    publisherOptions: get('dashboard/filters@publisherOptions'),
    pixelOptions: get('dashboard/filters@pixelOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    keywordOptions: get('dashboard/filters@keywordOptions'),
    audienceOptions: get('dashboard/filters@audienceOptions'),
    creativeOptions: get('dashboard/filters@creativeOptions'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    advertiser: get('common/advertiser'),
    totalOrUniqueRadioOptions: get('dashboard/filters@totalOrUniqueRadioOptions'),
    ioOptions: get('dashboard/filters@ioOptions'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    universalPixelId: get('common/universalPixelId'),
    selectedExportTableRow: sync('common/selectedExportTableRow'),
    selectedEvent: get('dashboard/GET_SELECTED_ALL_EVENTS'),
    selectedMediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    advertiserId() {
      return this.advertiser ? this.advertiser.id : 0;
    },
    // Check if the report name is valid (not empty after trimming)
    isValidReportName() {
      return this.reportName.trim().length > 0; // True if the name has valid characters
    },
    // Disable the button when Save Report is selected and no valid report name is provided
    isSaveButtonDisabled() {
      return false;
      // return this.saveTemplate && !this.isValidReportName; // Disable when reportName is invalid
    },
    // This computed property checks if "Creative" is the only option selected
    isCreativeOnly() {
      return (
        this.selectedDimensionsOptions.length === 1 &&
        this.selectedDimensionsOptions.includes('creative')
      );
    },
    hasOnlySingleEventSelected() {
      // const selectedEventsCount = this.eventOptions.filter((e) => e.selected).length;
      // return selectedEventsCount === 1;
      return false;
    },
    appliedFilterCount() {
      const filters = this.$store.copy('dashboard/filters');
      let appliedCount = 0;
      const filterKeys = [
        'campaignOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'creativeOptions',
        'audienceOptions',
        'keywordOptions',
      ];
      filterKeys.forEach((key) => {
        if (filters[key]?.filter((v) => v.checked)?.length > 0) {
          appliedCount += filters[key]?.filter((v) => v.checked)?.length ? 1 : 0;
        }
      });
      return appliedCount;
    },
    appliedFilterCountNew() {
      let appliedCount = 0;
      const filterUsed = this.filters;

      const filterKeys = [
        'campaignOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'audienceOptions',
        'creativeOptions',
        'publisherOptions',
      ];

      filterKeys.forEach((key) => {
        if (filterUsed[key]?.some((v) => v.checked)) {
          appliedCount += 1;
        }
      });
      return appliedCount;
    },
  },
  watch: {
    campaignOptions(n) {
      if (n) {
        this.filters.campaignOptions = JSON.parse(JSON.stringify(n));
        this.selectedFilters.campaignOptions = this.filters.campaignOptions.filter(
          (item) => item.checked
        );
      }
    },
    mediaTypeOptions(n) {
      if (n) {
        this.filters.mediaTypeOptions = JSON.parse(JSON.stringify(n));
      }
    },
    eventOptions(n) {
      if (n) {
        this.filters.eventOptions = JSON.parse(JSON.stringify(n));
      }
    },
    audienceOptions(n) {
      if (n) {
        this.filters.audienceOptions = JSON.parse(JSON.stringify(n));
      }
    },
  },
  async created() {
    EventBus.$on('advance-filter-on-apply', this.onAdvanceFilterApply);
    // Load options when component is mounted
    this.reportDimensionsOptions = this.getReportDimensionsOptions();

    this.setMetricOpts(true);
    this.setMetricOptsForEvents(this.reportMetricsOptions);
    // Initial load logic: check select all and select all items except key === 'ep'
    this.initializeMetricsSelection();
  },
  beforeDestroy() {
    EventBus.$off('advance-filter-on-apply', this.onAdvanceFilterApply);
  },
  async mounted() {
    this.getUserSavedReports(this.advertiser?.id);
    this.filters.template = 'new';
    this.$store.set('dashboard/filters@template', 'new');

    this.$store.dispatch('dashboard/resetEventOptions');

    if (this?.account?.id === MNI_ACCOUNT_ID || this?.account?.id === MNI_DEMO_ACCOUNT_ID) {
      this.mniAccount = true;
      this.$store.dispatch('common/setIsMniAccount', true);
    } else {
      this.mniAccount = false;
      this.$store.dispatch('common/setIsMniAccount', false);
    }

    const filterOptionsArray = [
      'audienceOptions',
      'creativeOptions',
      'adGroupOptions',
      'mediaTypeOptions',
      'campaignOptions',
      'ioOptions',
      'keywordOptions',
      'publisherOptions',
    ];
    this.selectedFilters = this.getSelectedFilters(this.genFilters(), filterOptionsArray);

    this.filters = this.genFilters();

    this.checkIfAllNecessaryDataIsAvailable();
  },
  methods: {
    showSuccessMessage(msg) {
      this.message = msg;
      this.messageType = 'success';
      this.displayMessage();
    },
    showErrorMessage(msg) {
      this.message = msg;
      this.messageType = 'error';
      this.displayMessage();
    },
    displayMessage() {
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 5000);
    },
    checkIfAllNecessaryDataIsAvailable() {
      if (this.startTime === null) {
        this.startTime = Date.now(); // Initialize the start time when the first retry happens
      }

      this.$nextTick(() => {
        // Check if all necessary data is available
        if (
          EventBus.campaignsReady &&
          EventBus.mediaReady &&
          EventBus.creativesReady &&
          EventBus.audienceReady
        ) {
          this.isApiCallsLoading = false;

          const initialFilterFromStorage = localStorage.getItem('initialReportBuilderFilters');
          if (initialFilterFromStorage !== undefined && initialFilterFromStorage !== null) {
            this.initialFilters = JSON.parse(initialFilterFromStorage);
          } else {
            this.initialFilters = JSON.parse(JSON.stringify(this.genFilters()));
            localStorage.setItem(
              'initialReportBuilderFilters',
              JSON.stringify(this.initialFilters)
            );
          }

          this.resetToDefaultValues(this.initialFilters);
          this.$nextTick(() => {
            this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
          });
        } else if (Date.now() - this.startTime < this.retryTimeout) {
          // Retry after a short delay
          this.isApiCallsLoading = true;
          setTimeout(() => this.checkIfAllNecessaryDataIsAvailable(), 300); // Retry after 300ms
        } else {
          this.isApiCallsLoading = false;
          console.error('Retry time exceeded 10 seconds. Some data might still be missing.');
        }
      });
    },
    genFilters() {
      const totalOrUniqueRadioOptions = this.$store.copy(
        'dashboard/filters@totalOrUniqueRadioOptions'
      );
      const showUniques = this.$store.copy('dashboard/filters@showUniques');
      if (showUniques) {
        totalOrUniqueRadioOptions[1].active = true;
        totalOrUniqueRadioOptions[0].active = false;
      }
      // interval
      const intervalOptions = this.$store.copy('dashboard/filters@intervalOptions');
      const interval = this.$store.copy('dashboard/filters@interval');
      intervalOptions.forEach((a) => {
        a.active = interval === a.id;
      });

      // methodology
      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      const methodology = this.$store.copy('dashboard/filters@methodology');
      methodologyOptions.forEach((mo) => {
        mo.active = methodology === mo.id;
      });
      const campaignOptions = this.$store.copy('dashboard/filters@campaignOptions');
      const audienceOpts = this.$store.copy('dashboard/filters@audienceOptions');
      const mediaTypeOpts = this.$store.copy('dashboard/filters@mediaTypeOptions');
      return {
        templateOptions: this.$store.copy('dashboard/filters@templateOptions'),
        template: this.$store.copy('dashboard/filters@template'),
        intervalOptions,
        interval,
        showUniques,
        methodology,
        methodologyOptions,
        conversionWindowUnit: this.$store.copy('dashboard/filters@conversionWindowUnit'),
        campaignOptions,
        conversionWindowDisplay: this.$store.copy('dashboard/filters@conversionWindowDisplay'),

        mediaTypeOpts,
        audienceOpts,
        creativeOptions: this.$store.copy('dashboard/filters@creativeOptions'),
        publisherOptions: this.$store.copy('dashboard/filters@publisherOptions'),
        eventOptions: this.$store.copy('dashboard/filters@eventOptions'),
      };
    },
    onAdvanceFilterApply(applyFilters) {
      this.filters.campaignOptions = applyFilters.campaignOptions;
      this.filters.adGroupOptions = applyFilters.adGroupOptions;
      this.filters.mediaTypeOptions = applyFilters.mediaTypeOptions;
      this.filters.creativeOptions = applyFilters.creativeOptions;
      this.filters.audienceOptions = applyFilters.audienceOptions;
      this.filters.publisherOptions = applyFilters.publisherOptions;
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
    },
    async setDefaultDates() {
      let computedDate = await get('dashboard/dates');
      const { startDate, endDate, dateRangeOption, compareWith } = generateFlipDefaultDates();
      computedDate = { ...this.dates, startDate, endDate, dateRangeOption, compareWith };
      this.$store.set('dashboard/dates', {
        ...this.dates,
        ...computedDate,
      });
      this.dateKey += 1;
    },
    resetToDefaultValues(initialFilters) {
      this.setDefaultDates();
      this.filters.conversionWindowUnit = initialFilters?.conversionWindowUnit;
      this.filters.conversionWindowDisplay = initialFilters?.conversionWindowDisplay;
      this.$store.set('dashboard/filters@campaignOptions', initialFilters?.campaignOptions);
      this.$store.dispatch('dashboard/resetToDefaultAPFilters');

      this.resetEventSelection();
      this.resetCampaignSelection(initialFilters);
    },
    resetAdvanceFilterOptions(initialFilters) {
      this.$nextTick(() => {
        this.filters.campaignOptions = initialFilters.campaignOptions;
        this.$store.set('dashboard/filters@campaignOptions', initialFilters?.campaignOptions);

        this.filters.mediaTypeOptions = initialFilters.mediaTypeOptions;
        this.$store.set('dashboard/filters@mediaTypeOptions', initialFilters?.mediaTypeOpts);

        this.filters.creativeOptions = initialFilters.creativeOptions;
        this.$store.set('dashboard/filters@creativeOptions', initialFilters?.creativeOptions);

        this.filters.audienceOptions = initialFilters.audienceOpts;
        this.$store.set('dashboard/filters@audienceOptions', initialFilters?.audienceOpts);
      });
    },
    resetCampaignSelection(initialFilters) {
      this.$nextTick(() => {
        this.filters.campaignOptions = initialFilters.campaignOptions;
        this.$store.set('dashboard/filters@campaignOptions', initialFilters?.campaignOptions);
      });
    },
    resetEventSelection() {
      this.eventOptions.forEach((event, index) => {
        // Only select the first event
        if (index === 0) {
          event.selected = true;

          // Uncheck subcategories, if they exist
          if (event.children && event.children.length) {
            event.children.forEach((subCategory) => {
              subCategory.selected = true;
            });
          }
        } else {
          // Ensure the rest of the events are unchecked
          event.selected = false;

          // Uncheck subcategories for the other events as well
          if (event.children && event.children.length) {
            event.children.forEach((subCategory) => {
              subCategory.selected = false;
            });
          }
        }
      });
    },
    onSelectTemplate(template) {
      if (template === 'all_touch' || template === 'last_touch') {
        this.reportName = '';
        this.selectedTemplateId = '';

        // disable dimensions
        this.selectedDimensionsOptions = [];
        this.disableAllDimensions = true;

        // disable metrics
        this.selectedMetricsOptions = [];
        this.selectAllMetrics = false;
        this.disabledAllMetrics = true;
        this.disabledAllEvents = false;
        // disable NA events
        // this.eventOptions.forEach((event) => {
        //   event.selected = false;

        //   // Uncheck subcategories, if they exist
        //   if (event.children && event.children.length) {
        //     event.children.forEach((subCategory) => {
        //       subCategory.selected = false;
        //     });
        //   }
        // });
        // // this.$store.set('dashboard/filters@eventOptions', this.eventOptions);
        // this.disabledAllEvents = true;
        // todo

        // reset to default values
        this.resetToDefaultValues(this.initialFilters);

        this.singleSelectMode = true;

        // disable save template checkbox
        this.disableSaveReportTemplate = true;
        this.runNow = true;
        this.handleRunNowChange(true);
      } else if (template === 'new') {
        this.isInitialCampaignChange = true;
        this.isInitialFilterChange = true;
        this.isInitialConversionWindowChange = true;
        this.isInitialConversionUnitChange = true;

        this.reportName = '';
        this.selectedTemplateId = '';
        this.disableAllDimensions = false;
        this.selectedDimensionsOptions = ['creative'];
        this.selectedReportKind = 'creative';
        this.setMetricOpts();

        this.disabledAllMetrics = false;
        this.disabledAllEvents = false;
        this.selectAllMetrics = true;

        this.singleSelectMode = false;

        this.disableSaveReportTemplate = false;

        // reset to default values
        this.resetToDefaultValues(this.initialFilters);
        this.setMetricOptsForEvents(this.reportMetricsOptions);
        this.toggleSelectAllMetrics();
      } else {
        this.selectAllMetrics = false;
        this.disableAllDimensions = false;
        this.disabledAllMetrics = false;
        this.disabledAllEvents = false;
        this.disableSaveReportTemplate = false;
        this.singleSelectMode = false;

        this.selectedTemplateId = template;

        const selectedOption = this.filters.templateOptions.find(
          (option) => option.id === template
        );
        if (selectedOption && selectedOption.filters) {
          // Parse the filters and set them to your template
          this.applyTemplateFilters(selectedOption.filters);
        }
        this.$nextTick(() => {
          this.reportName = selectedOption.name;
        });
      }
      this.$nextTick(() => {
        this.filters.template = template;
        this.$store.set('dashboard/filters@template', template);
      });
    },
    onSelectInterval(interval) {
      this.filters.interval = interval;
    },
    toggleAdvanceFilterModal() {
      EventBus.$emit('show-advance-filter', this.filters);
    },
    onClearAdvanceFilters() {
      this.resetAdvanceFilterOptions(this.initialFilters);
    },
    getReportDimensionsOptions() {
      let dimensions = [...NEW_REPORT_OPTIONS];
      if (!ACCOUNTS_TO_SHOW.includes(this.account?.id)) {
        dimensions = [];
        dimensions = NEW_REPORT_OPTIONS.filter((item) => item.id !== 'channel');
      }
      if (this.isMniAccount) {
        dimensions = NEW_REPORT_OPTIONS.filter((item) => item.id !== 'audience');
        dimensions.splice(5, 0, { id: 'audience', name: 'Placement', type: 'performance' });
      }
      const result = [...dimensions];
      return result;
    },
    setMetricOptsForEvents(reportMetricsOptions) {
      let opts = reportMetricsOptions; // Create a shallow copy of the array to avoid direct mutation

      let resetMetrics = true;
      this.selectedEvent.forEach((event) => {
        if (
          event.eventLabel === 'Leads' ||
          event.eventLabel === 'Checkouts' ||
          event.eventLabel === 'Page Visits'
        ) {
          resetMetrics = false;
        }
      });
      if (resetMetrics) {
        // put back conversion rate, cpa and pm
        if (!opts.some((item) => item.key === 'conversionRate')) {
          opts.push({
            key: 'conversionRate',
            name: '(%)Conversion Rate',
            value: 'Conversion Rate',
            formatter: { type: 'number', format: '0.00' },
          });
        }
        // if (!opts.some((item) => item.key === 'cpa')) {
        //   opts.push({
        //     key: 'cpa',
        //     name: 'CPA',
        //     value: 'CPA',
        //     formatter: { type: 'number', format: '0.00' },
        //   });
        // }
        if (!opts.some((item) => item.key === 'pm')) {
          opts.push({
            key: 'pm',
            name: 'Performance Multiplier',
            value: 'Performance Multiplier',
            formatter: { type: 'number', format: '0,0.[00]' },
          });
        }
      }

      // If selectedEvent is empty, remove the added metrics
      if (this.selectedEvent.length === 0) {
        opts = opts.filter(
          (item) =>
            ![
              'leadConversionRate',
              'leadCpa',
              'leadPm',
              'checkoutConversionRate',
              'checkoutCpa',
              'checkoutPm',
              'pageVisitConversionRate',
              'pageVisitCpa',
              'pageVisitPm',
            ].includes(item.key)
        );
        // put back conversion rate, cpa and pm
        if (!opts.some((item) => item.key === 'conversionRate')) {
          opts.push({
            key: 'conversionRate',
            name: '(%)Conversion Rate',
            value: 'Conversion Rate',
            formatter: { type: 'number', format: '0.00' },
          });
        }
        // if (!opts.some((item) => item.key === 'cpa')) {
        //   opts.push({
        //     key: 'cpa',
        //     name: 'CPA',
        //     value: 'CPA',
        //     formatter: { type: 'number', format: '0.00' },
        //   });
        // }
        if (!opts.some((item) => item.key === 'pm')) {
          opts.push({
            key: 'pm',
            name: 'Performance Multiplier',
            value: 'Performance Multiplier',
            formatter: { type: 'number', format: '0,0.[00]' },
          });
        }
      } else if (!resetMetrics) {
        // remove conversion rate, cpa and pm
        opts = opts.filter((item) => !['conversionRate', 'cpa', 'pm'].includes(item.key));
      }

      // Loop through selectedEvent and add metrics if necessary
      this.selectedEvent.forEach((event) => {
        if (event.eventLabel === 'Leads') {
          // Add metrics related to 'Leads' only if they are not already in opts
          if (!opts.some((item) => item.key === 'leadConversionRate')) {
            opts.push({
              key: 'leadConversionRate',
              name: '(%)Lead Conversion Rate',
              value: 'Lead Conversion Rate',
              formatter: { type: 'number', format: '0.00' },
            });
          }

          if (!opts.some((item) => item.key === 'leadCpa')) {
            opts.push({
              key: 'leadCpa',
              name: 'Lead CPA',
              value: 'Lead CPA',
              formatter: { type: 'number', format: '0.00' },
            });
          }

          if (!opts.some((item) => item.key === 'leadPm')) {
            opts.push({
              key: 'leadPm',
              name: 'Lead Performance Multiplier',
              value: 'Lead Performance Multiplier',
              formatter: { type: 'number', format: '0,0.[00]' },
            });
          }
        } else if (event.eventLabel === 'Checkouts') {
          // Add metrics related to 'Checkouts' only if they are not already in opts
          if (!opts.some((item) => item.key === 'checkoutConversionRate')) {
            opts.push({
              key: 'checkoutConversionRate',
              name: '(%)Checkout Conversion Rate',
              value: 'Checkout Conversion Rate',
              formatter: { type: 'number', format: '0.00' },
            });
          }

          if (!opts.some((item) => item.key === 'checkoutCpa')) {
            opts.push({
              key: 'checkoutCpa',
              name: 'Checkout CPA',
              value: 'Checkout CPA',
              formatter: { type: 'number', format: '0.00' },
            });
          }

          if (!opts.some((item) => item.key === 'checkoutPm')) {
            opts.push({
              key: 'checkoutPm',
              name: 'Checkout Performance Multiplier',
              value: 'Checkout Performance Multiplier',
              formatter: { type: 'number', format: '0,0.[00]' },
            });
          }
        } else if (event.eventLabel === 'Page Visits') {
          // Add metrics related to 'Checkouts' only if they are not already in opts
          if (!opts.some((item) => item.key === 'pageVisitConversionRate')) {
            opts.push({
              key: 'pageVisitConversionRate',
              name: '(%)Page Visit Conversion Rate',
              value: 'Page Visit Conversion Rate',
              formatter: { type: 'number', format: '0.00' },
            });
          }

          if (!opts.some((item) => item.key === 'pageVisitCpa')) {
            opts.push({
              key: 'pageVisitCpa',
              name: 'Page Visit CPA',
              value: 'Page Visit CPA',
              formatter: { type: 'number', format: '0.00' },
            });
          }

          if (!opts.some((item) => item.key === 'pageVisitPm')) {
            opts.push({
              key: 'pageVisitPm',
              name: 'Page Visit Performance Multiplier',
              value: 'Page Visit Performance Multiplier',
              formatter: { type: 'number', format: '0,0.[00]' },
            });
          }
        } else if (event.eventLabel === 'Revenue') {
          // Add metrics related to 'Checkouts' only if they are not already in opts
          if (!opts.some((item) => item.key === 'roas')) {
            opts.push({
              key: 'roas',
              name: '(%)ROAS',
              value: 'ROAS',
              formatter: { type: 'number', format: '0' },
            });
          }
        }
      });

      // Now check for any events that were removed and remove their metrics
      if (!this.selectedEvent.some((event) => event.eventLabel === 'Leads')) {
        opts = opts.filter(
          (item) => !['leadConversionRate', 'leadCpa', 'leadPm'].includes(item.key)
        );
      }

      if (!this.selectedEvent.some((event) => event.eventLabel === 'Checkouts')) {
        opts = opts.filter(
          (item) => !['checkoutConversionRate', 'checkoutCpa', 'checkoutPm'].includes(item.key)
        );
      }

      if (!this.selectedEvent.some((event) => event.eventLabel === 'Page Visits')) {
        opts = opts.filter(
          (item) => !['pageVisitConversionRate', 'pageVisitCpa', 'pageVisitPm'].includes(item.key)
        );
      }

      if (!this.selectedEvent.some((event) => event.eventLabel === 'Revenue')) {
        opts = opts.filter((item) => !['roas'].includes(item.key));
      }

      // Assign the modified array back to reportMetricsOptions
      this.reportMetricsOptions = opts;
    },
    setMetricOpts(isMounted = false) {
      if (this.selectedExportTableRow) {
        this.selectedReportKind = this.selectedExportTableRow;
      }
      const key = `${this.selectedReportType}`;
      let opts = JSON.parse(JSON.stringify(REPORT_BUILDER_METRIC_OPTIONS[key] || []));
      // remove incremental reach
      if (this.selectedReportKind !== 'publisher' && this.selectedReportKind !== 'overall') {
        opts = opts.filter((opt) => opt.key !== 'incrementalReach');
      }
      if (this.selectedEvent.isFalseEvent) {
        opts = opts.filter((opt) => !['checkouts', 'metric', 'pm'].includes(opt.key));
      }
      const disabledOptions = REPORT_BUILDER_DISABLED_METRICS[this.selectedReportKind] || [];
      const isAllEvnOptSelected = this.reportMetricsOptions.filter((op) => op.key === 'ep')?.[0]
        ?.checked;
      if (isAllEvnOptSelected && key !== 'rollup') {
        this.allEventPerformance = true;
        return;
      }
      // rename video completions rate and video completions to completion rate and completions respectively
      opts = opts.map((item) => {
        if (item.key === 'videoCompletionRate') item.value = `(%)Completion Rate`;
        if (item.key === 'videoCompletions') item.value = `Completions`;
        return item;
      });
      opts.forEach((opt) => {
        // if the option is key not found in disabled option then set checked = true
        const isOptionDisabled = disabledOptions.includes(opt.key);
        opt.checked = !isOptionDisabled;
        opt.disabled = isOptionDisabled;

        if (this.selectedExportTableRow) {
          if (['reach', 'frequency'].includes(opt.key)) {
            opt.checked = false;
          } else if (this.selectedEvent.isFalseEvent) {
            if (['videoCompletions', 'videoCompletionRate'].includes(opt.key)) {
              opt.checked = this.selectedEvent.isVcrEvent;
            }
            if (['clicks', 'clickThroughRate'].includes(opt.key)) {
              opt.checked = this.selectedEvent.isCtrEvent;
            }
          } else if (
            ['videoCompletions', 'videoCompletionRate', 'clicks', 'clickThroughRate'].includes(
              opt.key
            )
          ) {
            opt.checked = false;
          }
        }
        if (key === 'performance' && opt.key === 'checkouts') {
          opt.key = this.selectedEvent.isRevenueEvent ? 'revenue' : 'checkouts';
          opt.name = this.selectedEvent.isRevenueEvent ? '($)Rev' : this.selectedEventLabel;
          opt.value = this.selectedEventLabel;
          opt.formatter.format = this.selectedEvent.isRevenueEvent ? '0,0.00' : '0,0.[00]';
        } else if (key === 'performance' && opt.key === 'metric') {
          opt.checked =
            isMounted ||
            this.reportMetricsOptions.filter((oa) => oa.key === 'cpa' || oa.key === 'roas')?.[0]
              ?.checked;
          if (this.selectedEventLabel === 'Incremental Reach') {
            opt.key = 'incrementalReachPercentage';
            opt.name = 'Inc. Reach %';
            opt.formatter.format = '0';
          } else {
            const unit = this.selectedEvent.isRevenueEvent ? '%' : '$';
            opt.key = this.selectedEvent.isRevenueEvent ? 'roas' : 'cpa';
            opt.name = `(${unit})${this.selectedEvent.metric}`;
            opt.formatter.format = this.selectedEvent.isRevenueEvent ? '0' : '0,0.00';
          }

          opt.infinityKey = `${opt.key}Infinity`;

          opt.value = this.selectedEvent.metric;
        }
        if (opt.key === 'ep') {
          opt.checked = false;
        }
      });
      // remove incremental reach %
      if (this.selectedReportKind !== 'publisher' && this.selectedReportKind !== 'overall') {
        opts = opts.filter((opt) => opt.key !== 'incrementalReachPercentage');
      }
      if (this.selectedReportKind === 'overall') {
        REPORT_BUILDER_EXTRA_METRICS.forEach((rbem) => {
          opts.push({ ...rbem, checked: false });
        });
      }
      if (this.selectedEventLabel === 'Incremental Reach') {
        opts.forEach((op) => {
          if (op.key === 'incrementalReachPercentage') {
            op.value = 'Inc. Reach %';
            op.checked = true;
          }
          return op;
        });
      }

      this.reportMetricsOptions = opts.filter(
        (opt) => opt.value && opt.name !== 'Incremental Reach'
      );

      if (!isMounted) {
        this.setMetricOptsForEvents(this.reportMetricsOptions);
      }
    },
    onSelectMethodology(method) {
      this.filters.methodology = method;
    },
    runReport() {
      // Placeholder function for button action
      console.log('Report settings:', {
        runNow: this.runNow,
        exportResults: this.exportResults,
        scheduleReport: this.scheduleReport,
        reportFrequency: this.reportFrequency,
        emailRecipients: this.emailRecipients,
        saveTemplate: this.saveTemplate,
        reportName: this.reportName,
      });
    },
    bDatepickerChanges(data) {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }

      const datePickerPayload = {
        startDate: data.dates[0],
        endDate: data.dates[1],
        dateRangeOption: data.dateRangeOption,
      };
      this.$store.set('dashboard/dates', datePickerPayload);
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
      this.$forceUpdate();
    },
    getSelectedFilters(filters, filterOptionsArray) {
      const selectFilterVals = {};
      filterOptionsArray?.forEach((fl) => {
        if (filters[fl]?.length) {
          selectFilterVals[fl] = filters[fl]?.filter((f) => f.checked);
        } else {
          selectFilterVals[fl] = [];
        }
      });
      return JSON.parse(JSON.stringify(selectFilterVals));
    },
    handleRunNowChange(val) {
      this.runNow = val;
      // if (this.runNow) {
      //   // If "Run now" is checked, uncheck "Save as report template"
      //   this.saveTemplate = false;
      //   this.buttonLabel = 'RUN REPORT'; // Update button label
      // } else {
      //   // If "Run now" is unchecked, automatically check "Save as report template"
      //   this.saveTemplate = true;
      //   this.buttonLabel = 'SAVE REPORT';
      //   // Use $nextTick to ensure the DOM is updated before focusing the input
      //   this.$nextTick(() => {
      //     this.$refs.reportNameInput.focus(); // Focus the report name input
      //   });
      // }
    },
    handleSaveTemplateChange(val) {
      this.saveTemplate = val;
      // if (this.saveTemplate) {
      //   // If "Save as report template" is checked, uncheck "Run now"
      //   this.runNow = false;
      //   this.buttonLabel = 'SAVE REPORT'; // Update button label
      //   // Focus on the input box when "Save as report template" is checked
      //   this.$nextTick(() => {
      //     this.$refs.reportNameInput.focus();
      //   });
      // } else {
      //   // If "Save as report template" is unchecked, automatically check "Run now"
      //   this.runNow = true;
      //   this.buttonLabel = 'RUN REPORT';
      // }
    },
    trimReportName(reportName) {
      this.reportName = reportName?.trim(); // Trim leading and trailing spaces
      this.filters.template = 'new';
      this.$store.set('dashboard/filters@template', 'new');
      this.selectedTemplateId = '';
    },
    applyTemplateFilters(templateFilters) {
      // this.selectedMetricsOptions = templateFilters.reportMetrics.map((item) => item.metrics);
      this.selectedDimensionsOptions = templateFilters.dimensions;
      if (this.selectedDimensionsOptions.includes('publisher')) {
        this.selectedReportKind = 'publisher';
      } else if (this.selectedDimensionsOptions.includes('overall')) {
        this.selectedReportKind = 'overall';
      }
      this.setMetricOpts();

      // Set selectedMetricsOptions (for event=false cases)
      this.selectedMetricsOptions = templateFilters.reportMetrics
        .filter((item) => item.event === false)
        .map((item) => item.metrics);

      if (this.selectedMetricsOptions.length === 1 && this.selectedMetricsOptions[0] === 'ep') {
        this.handleMetricsChange('ep');
      }

      if (
        this.reportMetricsOptions.length ===
        templateFilters.reportMetrics.filter((item) => item.event === false).length + 1
      ) {
        this.selectAllMetrics = true;
        this.toggleSelectAllMetrics();
      }

      // Iterate over eventOptions and restore `selected` values for `event=true` items
      this.eventOptions.forEach((event) => {
        const matchedEvent = templateFilters.reportMetrics.find(
          (item) =>
            this.formatEventNamesReverse(item.metrics) === event.event && item.event === true
        );

        // If event is found in reportMetrics with event=true
        if (matchedEvent) {
          event.selected = true; // Mark main event as selected

          // For subcategories, restore `selected` values if they exist in matchedEvent
          if (event.children && event.children.length > 0) {
            event.children.forEach((subCategory) => {
              const matchedSubCategory = matchedEvent.subCategory.find(
                (sub) => sub === subCategory.event
              );
              // If subcategory is in the matchedEvent's subCategory list, mark it as selected
              subCategory.selected = !!matchedSubCategory;
            });
          }
        } else {
          // If the event wasn't found in the matchedEvent, uncheck the event and its subcategories
          event.selected = false;
          if (event.children && event.children.length > 0) {
            event.children.forEach((subCategory) => {
              subCategory.selected = false; // Uncheck all subcategories for this event
            });
          }
        }
      });

      this.setMetricOptsForEvents(this.reportMetricsOptions);

      this.filters.conversionWindowDisplay = templateFilters.conversionWindow;
      this.filters.conversionWindowUnit = templateFilters.conversionWindowUnit;

      const campaignOpts = this.filters.campaignOptions;
      campaignOpts.forEach((campaign) => {
        campaign.checked = templateFilters.campaignIds?.includes(String(campaign.campaignId));
      });
      this.$store.set('dashboard/filters@campaignOptions', campaignOpts);

      let datePickerPayload = {};
      if (templateFilters.dateRangeType === 'LAST_7_DAYS') {
        datePickerPayload = {
          startDate: convertEpochToNYTimezone(offsetDate(8)),
          endDate: convertEpochToNYTimezone(offsetDate(2)),
          dateRangeOption: '6',
        };
      } else if (templateFilters.dateRangeType === 'LAST_2_WEEKS') {
        datePickerPayload = {
          startDate: convertEpochToNYTimezone(offsetDate(15)),
          endDate: convertEpochToNYTimezone(offsetDate(2)),
          dateRangeOption: '14',
        };
      } else if (templateFilters.dateRangeType === 'LAST_30_DAYS') {
        datePickerPayload = {
          startDate: convertEpochToNYTimezone(offsetDate(31)),
          endDate: convertEpochToNYTimezone(offsetDate(2)),
          dateRangeOption: '7',
        };
      } else if (templateFilters.dateRangeType === 'CAMPAIGN_TO_DATE') {
        const dateRange = getCampaignDateRange(campaignOpts, true);
        datePickerPayload = {
          startDate: convertEpochToNYTimezone(dateRange.startDate),
          endDate: convertEpochToNYTimezone(dateRange.endDate),
          dateRangeOption: '13',
        };
      } else if (templateFilters.dateRangeType === 'CUSTOM') {
        datePickerPayload = {
          startDate: templateFilters.startDate,
          endDate: templateFilters.endDate,
          dateRangeOption: '0',
        };
      }

      // this.dates.startDate = templateFilters.startDate;
      // this.dates.endDate = templateFilters.endDate;
      this.$store.set('dashboard/dates', datePickerPayload);
      this.dateKey += 1;

      const creativeOpts = [...this.filters.creativeOptions];
      creativeOpts.forEach((creatives) => {
        creatives.checked = templateFilters.creatives?.includes(String(creatives.key));
      });
      this.$store.set('dashboard/filters@creativeOptions', creativeOpts);

      const mediaTypeOpts = this.filters.mediaTypeOptions;
      mediaTypeOpts.forEach((media) => {
        media.checked = templateFilters.mediaTypes?.includes(String(media.value));
      });
      this.$store.set('dashboard/filters@mediaTypeOptions', mediaTypeOpts);

      const audienceOpts = this.filters.audienceOptions;
      audienceOpts.forEach((audience) => {
        audience.checked = templateFilters.audiences?.includes(String(audience.value));
      });
      this.$store.set('dashboard/filters@audienceOptions', audienceOpts);
    },
    formatEventNames(name) {
      const eventMap = {
        Leads: 'lead',
        Checkouts: 'checkout',
        Revenue: 'revenue',
        'Page Visits': 'pageVisit',
        'Video Completions': 'completions',
        Clicks: 'clicks',
      };

      return eventMap[name] || name;
    },
    formatEventNamesReverse(name) {
      const eventMap = {
        lead: 'Leads',
        checkout: 'Checkouts',
        revenue: 'Revenue',
        pageVisit: 'Page Visits',
        completions: 'Video Completions',
        clicks: 'Clicks',
      };

      return eventMap[name] || name;
    },
    runOrSaveReport() {
      const transformedReportMetrics = this.selectedMetricsOptions.map((metric) => ({
        metrics: metric,
        event: false,
        subCategory: [],
      }));
      const transformedEventOptions = this.eventOptions
        .filter((option) => option.selected) // Filter only the items where `selected` is true
        .map((option) => ({
          metrics: this.formatEventNames(option.event), // Use `category` value as `metrics` (instead of `event`)
          event: true, // Set `isEvent` to "true"
          subCategory:
            option.children?.length > 0
              ? option.children.filter((child) => child.selected).map((child) => child.event) // Map `event` values as `subCategory`
              : [], // If no children, return an empty array
        }));
      const mergedMetrics = transformedReportMetrics.concat(transformedEventOptions);

      const dates = this.$store.get('dashboard/dates');
      let dateRangeType = null;
      if (String(dates.dateRangeOption) === '6') {
        dateRangeType = 'LAST_7_DAYS';
      } else if (String(dates.dateRangeOption) === '14') {
        dateRangeType = 'LAST_2_WEEKS';
      } else if (String(dates.dateRangeOption) === '7') {
        dateRangeType = 'LAST_30_DAYS';
      } else if (String(dates.dateRangeOption) === '13') {
        dateRangeType = 'CAMPAIGN_TO_DATE';
      } else if (String(dates.dateRangeOption) === '0') {
        dateRangeType = 'CUSTOM';
      }

      const data = this.filters.campaignOptions;
      const result = data.filter((f) => f?.checked);
      const campaignIds = result?.map((res) => {
        return res.key;
      });
      // Construct the payload for the report
      const payload = {
        accountId: this?.account?.id,
        advertiserId: this.advertiser?.id,
        // Applied filters
        filters: {
          adGroups: null,
          audiences: this.selectedAudiences,
          campaignIds,
          conversionWindow: this.filters.conversionWindowDisplay,
          conversionWindowUnit: this.filters.conversionWindowUnit,
          creatives: this.selectedCreatives,
          dateRangeType,
          dimensions: this.selectedDimensionsOptions,
          endDate: (() => {
            if (dateRangeType === 'CUSTOM') {
              return formatDateForAPI(dates.endDate);
            }
            if (['all_touch', 'last_touch'].includes(this.filters.template)) {
              return formatDateForAPI(dates.endDate);
            }
            return null;
          })(),
          startDate: (() => {
            if (dateRangeType === 'CUSTOM') {
              return formatDateForAPI(dates.startDate);
            }
            if (['all_touch', 'last_touch'].includes(this.filters.template)) {
              return formatDateForAPI(dates.startDate);
            }
            return null;
          })(),
          interval: this.filters.interval?.toUpperCase(),
          keywords: null,
          mediaTypes: this.selectedMediaTypes,
          methodology: this.filters.methodology?.toUpperCase(),
          reportMetrics: mergedMetrics,
          pixelIds: null,
          publisher: this.selectedPublishers,
          showUniques: this.filters.showUniques,
        },
        // Report configuration
        name: this.reportName.trim(),
      };

      // Validate the payload (e.g., report name must be provided if saving a template)
      if (
        this.saveTemplate &&
        !this.reportName &&
        !['all_touch', 'last_touch'].includes(this.filters.template)
      ) {
        this.showErrorMessage(
          'Please provide a valid report name (cannot be empty or spaces only).'
        );
        return;
      }

      // Debug log the payload to ensure everything is structured correctly
      console.log('payload', payload);

      if (['all_touch', 'last_touch'].includes(this.filters.template)) {
        EventBus.$emit('switch-to-tab-index', 2);
        payload.filters.template = this.filters.template;
        this.triggerSearch(payload);
      } else if (this.saveTemplate && this.filters.template === 'new') {
        console.log('saving template...');
        // Only new template can be save
        const url = `${config.ADREADY_URL}/api/ac/advertisers/${this.advertiser?.id}/reportTemplate`;
        const apiCall = axios.post(url, payload, {
          withCredentials: true,
        });
        apiCall
          .then((response) => {
            if (response?.data?.status === 200) {
              // success saving the template
              if (this.runNow) {
                // run now
                console.log('running report...');
                EventBus.$emit('switch-to-tab-index', 2);
                this.triggerReportSearchWithoutID(payload);
              } else {
                // go to my reports
                EventBus.$emit('switch-to-tab-index', 1);
              }
            }
          })
          .catch((error) => {
            // do not run report if there is an issue with saving template
            // Extract the error message from the response
            const errorMessage = error.response?.data?.message || 'An unknown error occurred';
            console.error('Error:', errorMessage);

            // Handle the specific error or notify the user
            this.showErrorMessage(errorMessage);
          });
      } else if (this.runNow) {
        // run now
        let payLoad = {};
        if (this.selectedTemplateId && this.selectedTemplateId !== '') {
          payLoad = {
            id: this.selectedTemplateId,
            advertiserId: payload.advertiserId,
          };
        } else {
          payLoad = payload;
        }

        EventBus.$emit('switch-to-tab-index', 2);
        this.triggerReportSearch(payLoad);
      } else {
        this.showErrorMessage('Please select an option under Delivery');
      }

      // Set the API call based on whether we're saving a template or running the report
      // const apiCall = this.saveTemplate
      //   ? axios.post(url, payload, {
      //       withCredentials: true,
      //     }) // Save template
      //   : axios.post(`${url}/run`, payload); // Run report (assumed API endpoint for running)

      // // Make the API call
      // apiCall
      //   .then((response) => {
      //     if (response?.data?.status === 200) {
      //       EventBus.$emit('switch-to-tab-index', 1);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('Error:', error);
      //   });
    },
    triggerReportSearchWithoutID(payload) {
      if (EventBus.myReportsWithIDListenerReady) {
        // If the listener is ready, emit the event
        EventBus.$emit('do-new-report-search-from-my-reports-with-id', payload);
      } else {
        // If the listener isn't ready, retry after a short delay
        setTimeout(() => this.triggerReportSearchWithoutID(payload), 200); // Retry after 200ms
      }
    },
    triggerReportSearch(payload) {
      if (EventBus.myReportsListenerReady) {
        // If the listener is ready, emit the event
        EventBus.$emit('do-new-report-search-from-my-reports', payload);
      } else {
        // If the listener isn't ready, retry after a short delay
        setTimeout(() => this.triggerReportSearch(payload), 200); // Retry after 200ms
      }
    },
    triggerSearch(payload) {
      this.$nextTick(() => {
        if (EventBus.listenerReady) {
          // If the listener is ready, emit the event
          EventBus.$emit('do-new-report-search', payload);
        } else {
          // If the listener isn't ready, retry after a short delay
          console.log('Listener not ready, will retry...');
          setTimeout(this.triggerSearch(payload), 100); // Retry after 100ms
        }
      });
    },
    setSelectedFilters(name, values, isMultiSelectFilter = false) {
      if (!this.isInitialCampaignChange) {
        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }
      }
      this.isInitialCampaignChange = false;

      if (isMultiSelectFilter) {
        if (name === 'adGroupOptions') {
          this.selectedAdGroup = values;
        }
        this.setFilterOptions(name, values);
      } else {
        this.selectedFilters[name] = values.map((val) => {
          val.checked = true;
          return val;
        });
        const formattedOption = this.filters[name].map((opt) => {
          if (values.find((val) => val.value === opt.value)) {
            opt.checked = true;
          } else {
            opt.checked = false;
          }
          return opt;
        });

        this.setFilterOptions(name, formattedOption);
      }
    },
    setFilterOptions(name, values) {
      this.filters[name] = values;
      if (name === 'campaignOptions') {
        this.$store.set('dashboard/filters@campaignOptions', values);
      }
    },
    handleDimensionChange(opt) {
      this.$nextTick(() => {
        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }

        // Ensure at least one option is selected (default to "Creative" if nothing is selected)
        // if (this.selectedDimensionsOptions.length === 0) {
        //   this.selectedDimensionsOptions = ['creative'];
        // }
        // Check if "Overall" is included in selectedDimensionsOptions
        const overallIndex = this.selectedDimensionsOptions.indexOf('overall');

        if (overallIndex === -1) {
          // "Overall" is not selected, do nothing
          if (this.selectedDimensionsOptions.includes('publisher')) {
            this.selectedReportKind = 'publisher';
          } else {
            this.selectedReportKind = '';
          }
          this.setMetricOpts();
          return;
        }

        // If "Overall" is at the end of selectedDimensionsOptions
        if (overallIndex === this.selectedDimensionsOptions.length - 1) {
          // Keep only "Overall", uncheck all others
          this.selectedDimensionsOptions = ['overall'];
          this.selectedReportKind = opt;
          this.setMetricOpts();
        } else if (overallIndex === 0) {
          // If "Overall" is at index 0, unselect "Overall"
          this.selectedDimensionsOptions.splice(overallIndex, 1);
          this.selectedReportKind = opt;
          this.setMetricOpts();
        }
      });
    },
    toggleSelectAllMetrics() {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }

      if (this.selectAllMetrics) {
        // Check all options except 'ep'
        this.disabledAllEvents = false;
        this.selectedMetricsOptions = this.reportMetricsOptions
          .map((option) => option.key)
          .filter((key) => key !== 'ep');
        this.resetEventSelection();
      } else {
        // If unchecking "select all", check the first option after 'ep'
        const epIndex = this.reportMetricsOptions.findIndex((option) => option.key === 'ep');
        if (epIndex !== -1 && epIndex + 1 < this.reportMetricsOptions.length) {
          this.selectedMetricsOptions = [this.reportMetricsOptions[epIndex].key];
          this.handleMetricsChange('ep');
        } else {
          this.selectedMetricsOptions = [];
        }
      }
    },
    initializeMetricsSelection() {
      this.$nextTick(() => {
        this.selectAllMetrics = true;
        this.selectedMetricsOptions = this.reportMetricsOptions
          .map((option) => option.key)
          .filter((key) => key !== 'ep');
      });

      // reset event selection
      this.resetEventSelection();
    },
    handleMetricsChange(selected) {
      this.$nextTick(() => {
        if (this.disabledAllEvents) {
          this.resetEventSelection();
          this.disabledAllEvents = false;
        }

        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }
        // If "ep" is checked, deselect all others
        if (selected === 'ep') {
          if (this.selectedMetricsOptions.length === 0) {
            this.selectAllMetrics = true;
            this.toggleSelectAllMetrics();
            this.resetEventSelection();
            this.disabledAllEvents = false;
          } else {
            this.selectedMetricsOptions = ['ep'];
            this.selectAllMetrics = false;

            // Uncheck all event options
            this.eventOptions.forEach((event) => {
              event.selected = false;

              // Uncheck subcategories, if they exist
              if (event.children && event.children.length) {
                event.children.forEach((subCategory) => {
                  subCategory.selected = false;
                });
              }
            });

            this.disabledAllEvents = true;
          }
          return;
        }

        // If "ep" is currently selected and another option is checked, uncheck "ep"
        if (this.selectedMetricsOptions.includes('ep')) {
          this.selectedMetricsOptions = this.selectedMetricsOptions.filter(
            (option) => option !== 'ep'
          );
        }

        // Check if all items except "ep" are selected
        const allExceptEPSelected = this.reportMetricsOptions
          .filter((option) => option.key !== 'ep')
          .every((option) => this.selectedMetricsOptions.includes(option.key));

        // If all except "ep" are selected, check "select all"
        this.selectAllMetrics = allExceptEPSelected;

        // If "ep" is unchecked, check "select all" if all other options are selected
        if (
          !this.selectedMetricsOptions.includes('ep') &&
          this.selectedMetricsOptions.length === 0
        ) {
          this.selectAllMetrics = true;
          this.toggleSelectAllMetrics();
        }

        // Ensure at least one item is selected
        if (this.selectedMetricsOptions.length === 0) {
          const epIndex = this.reportMetricsOptions.findIndex((option) => option.key === 'ep');
          if (epIndex !== -1 && epIndex + 1 < this.reportMetricsOptions.length) {
            this.selectedMetricsOptions = [this.reportMetricsOptions[epIndex + 1].key];
          }
        }
      });

      // Ensure at least one item is selected
      // if (this.selectedMetricsOptions.length === 0) {
      //   this.initializeMetricsSelection();
      // }
    },
    toggleMainCategory(index) {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }

      if (this.singleSelectMode) {
        // If singleSelectMode is true, uncheck other checkboxes
        this.eventOptions.forEach((event, i) => {
          if (i !== index) {
            event.selected = false; // Uncheck other events
          }
        });
      }
      const mainEvent = this.eventOptions[index];

      // If the main category is checked, check all subcategories
      if (mainEvent.selected) {
        mainEvent.children?.forEach((subCategory) => {
          subCategory.selected = true;
        });
      }

      this.setMetricOptsForEvents(this.reportMetricsOptions);
      // this.$store.set('dashboard/filters@eventOptions', this.eventOptions);
    },
    resetSubCategories(index) {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }

      const mainEvent = this.eventOptions[index];

      // Reset all subcategories to selected when the main category is checked again
      if (mainEvent.selected) {
        mainEvent.children?.forEach((subCategory) => {
          if (!subCategory.selected) {
            subCategory.selected = true;
          }
        });
      }
    },
    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    updateConversionWindowUnit(val) {
      if (!this.isInitialConversionUnitChange) {
        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }
      }
      this.isInitialConversionUnitChange = false;

      // Check if the selected unit is already the current one
      if (this.filters.conversionWindowUnit === val.name.toUpperCase()) {
        return; // Exit the function if the unit is unchanged
      }

      if (val.name.toUpperCase() === 'DAYS') {
        this.filters.conversionWindowDisplay =
          this.filters.conversionWindowDisplay / 24 < 1
            ? 1
            : this.filters.conversionWindowDisplay / 24;
      } else {
        this.filters.conversionWindowDisplay *= 24;
      }
      this.filters.conversionWindowUnit = val.name.toUpperCase();

      // Validate after updating the unit
      this.validateConversionWindow();
    },
    validateConversionWindow() {
      if (!this.isInitialConversionWindowChange) {
        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }
      }
      this.isInitialConversionWindowChange = false;

      const maxValue = this.filters.conversionWindowUnit === 'DAYS' ? 31 : 744;
      const value = parseFloat(this.filters.conversionWindowDisplay);

      // Cap the value at the maximum but allow zero during typing
      if (value > maxValue) {
        this.filters.conversionWindowDisplay = maxValue;
      }
    },
    handleBlur() {
      const value = parseFloat(this.filters.conversionWindowDisplay);

      // If the value is zero or invalid, reset it to 1
      if (Number.isNaN(value) || value <= 0) {
        this.filters.conversionWindowDisplay = 1;
      }
    },
    async getUserSavedReports(advertiserId) {
      let data = null;
      try {
        data = await appConsoleDataLayer.getUserSavedReports(advertiserId);
        if (data?.length > 0) {
          const dynamicOptions = data
            .map((item) => ({
              id: item.id,
              name: item.name,
              filters: item.filters, // Attach filters to use on selection
            }))
            .sort((a, b) => b.id - a.id); // Sort by id in descending order
          const defaultTemplate = this.$store.copy('dashboard/filters@templateOptions');
          const allTemplates = [...defaultTemplate, ...dynamicOptions];
          // Combine static and dynamic options
          this.filters.templateOptions = allTemplates;
          // this.$store.set('dashboard/filters@templateOptions', allTemplates);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 21% 21% 15% 23% 20%;
  padding-top: 20px;
  font-family: 'Source Sans Pro', sans-serif;
}
.grid-item {
  padding: 10px;
  max-width: 230px;
  /* border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9; */
}
.section {
  display: block;
  margin-bottom: 8px;
}
.filter {
  ::v-deep .dropdown .dropdown-menu {
    li svg {
      font-size: inherit !important;
    }
    .intip4 {
      top: 2px !important;
      margin-left: 5px !important;
      &::after {
        bottom: 200% !important;
        width: 198px !important;
        margin-left: -73px !important;
        font-weight: 400 !important;
        text-align: left !important;
        letter-spacing: 0em !important;
      }
    }
  }
  .filter-ttl {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.1em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .filter-group > *:not(:first-child) {
    margin-top: 10px;
  }

  .filter-group {
    margin-bottom: 15px;
    .conv-content {
      position: relative;
      margin-top: 15px;

      .conv-ttl {
        position: absolute;
        top: -80%;
        font-size: 14px;
        color: #707e8a;
      }
    }

    .opt-b1 {
      display: inline-block;
      margin-right: 6px;
    }
    .opt-b2 {
      display: inline-block;
      width: 10rem;
      margin-left: 70px;
      vertical-align: bottom;
      position: absolute;
      margin-top: 5px;
      & .checkbox-btn {
        height: unset;
      }
      ::v-deep .checkbox-btn {
        span {
          position: fixed;
          margin: 0 30px !important;
          font-size: 12px !important;
        }
      }
    }

    .input-conv {
      width: 60px;
    }

    .flp-input {
      padding: 10px;
      background-color: transparent;
      border-radius: 4px;
    }
  }
}

.pan-content-top {
  /* margin-left: -26px !important; */
}
.aggregation {
  padding-top: 5px;
}
::v-deep .media-settings {
  .filter-group > *:not(:first-child) {
    margin-top: 12px !important;
  }
  .dropdown {
    width: 200px !important;
    min-width: 180px !important;
    margin: 0 0 0 0 !important;
  }
}
::v-deep .filter.media-settings .dropdown .dropdown-menu {
  max-height: 300px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  li svg {
    font-size: 17px !important;
    box-shadow: inset 0px 0px 1px 1px #454546;
    color: #fff;
  }
}
.advance-filter-clear-icon {
  position: relative;
  top: -2px;
  left: 5px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    color: var(--primarycolor) !important;
  }
}

.fp-text-link {
  font-size: 12px;
  color: #707e8a;
  display: inline-block;
  margin-top: 15px;
  display: flex;
  align-items: center;
  & p {
    text-decoration: underline;
    cursor: pointer;
  }
  & p:hover {
    color: var(--primarycolor) !important;
  }
  & span {
    text-decoration: none;
    margin-left: 8px;
    color: #212325 !important;
    .advance-filter-clear-icon:hover {
      color: var(--primarycolor) !important;
    }
  }
  & span:hover {
    color: #cad1d6;
  }
}
::v-deep .method-settings {
  .dropdown {
    width: 198px !important;
    min-width: 198px !important;
  }
  .filter-group {
    .tot-uniq,
    .conv-content {
      .conversion-opt-cont {
        display: flex;
      }
    }
  }
}
.dimension-item {
  margin: 5px 0;
}

.dimension-item input[type='checkbox'] {
  margin-right: 10px;
  cursor: pointer;
}

.dimension-item label {
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

h3 {
  margin-bottom: 15px;
  color: #333;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-group input[type='checkbox'] {
  margin-right: 10px;
}

.schedule-options label,
.form-group label {
  margin-bottom: 5px;
}

input[type='text'],
select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.run-report-button {
  background-color: var(--primarycolor);
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  width: 100%;
}

.run-report-button:hover {
  background-color: #003366;
}
.drp-wrap {
  font-size: 13px !important;
  padding: 6px 0px 6px 0px !important;
}
.media-select {
  font-size: 13px !important;
}
.filter {
  &.checkbox-select {
    position: relative;
    color: var(--primarydark2);
    max-width: 549px;
    width: 100%;

    max-height: 600px; /* Adjust the height */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .checkbox-select::-webkit-scrollbar {
    width: 8px;
  }

  .checkbox-select::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  .checkbox-select::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .checkbox-select__trigger {
    border-radius: 10px;
    background: #fff;
    position: relative;
    z-index: 1;
    height: auto;
    max-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 10px 8px 15px;
    border: 1px solid #c5d2d8;
    border-radius: 6px;
    transition: all 0.4s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox-select__check-wrapp {
    margin-bottom: 0px;
    position: relative;

    input[type='checkbox'] {
      display: none;

      & + label {
        position: relative;
        cursor: pointer;
        font-family: 'Manrope', sans-serif;
        font-size: 13px;
        line-height: 22px;
        padding-left: 30px;
        display: inline-block;
        border-radius: 5px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: padding 0.25s ease;

        &:after {
          border: solid 2px var(--primarycolor);
          border-radius: 5px;
          content: '';
          width: 18px;
          height: 18px;
          opacity: 0.7;
          top: 2px;
          left: 0;
          position: absolute;
        }

        &:before {
          width: 14px;
          height: 14px;
          content: '';
          position: absolute;
          top: 0px;
          left: 4px;
          background-color: #fff;
          opacity: 0;
          will-change: transform;
          transform: scale(0.5);
          transition: all 0.2s ease;
        }

        &:hover {
          padding-left: 32px;
        }
      }

      &:disabled {
        color: var(--primarydark);
        opacity: 0.6;
        cursor: auto;
      }

      &:checked {
        & + label {
          &:before {
            margin: 1px 0px 0px 0px;
            opacity: 0.7;
            color: var(--primarycolor);
            font-size: 12px;
            font-family: FontAwesome;
            content: '\f00c';
            transform: scale(1);
          }
        }
      }
    }
  }
}
.checkbox-select__check-wrapp {
  margin-bottom: 10px;
}

.subcategory-container {
  margin-left: 20px; /* Indent subcategories */
  padding-top: 5px; /* Add some spacing from the parent */
}

.subcategory-item {
  margin-bottom: 8px; /* Spacing between subcategories */
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.tooltip-icon {
  margin-left: 5px;
  color: #5a5a5a; /* Neutral gray for icon */
  cursor: pointer;
}

.campaign-opts {
  width: 200px !important;
}

::v-deep .campaign-opts .checkbox-select {
  &__dropdown {
    max-width: 200px !important;
  }
}

.b-select {
  width: auto;
  min-width: 200px;
  max-width: 200px;
  margin-right: 20px !important;
  ::v-deep.select {
    margin-top: 0;
    .selected-opt {
      display: block;
      width: 164px !important;
    }
  }
}

::v-deep .dropdown {
  max-width: 200px;
  background-color: #30323a;
  transition: unset !important;
  .select {
    height: 40px;
  }
  .dropdown-menu {
    background-color: #30323a;
    li {
      display: flex;
      padding: 10px 12px !important;
      font-size: 13px !important;
      &:first-child {
        text-transform: capitalize !important;
      }
    }
  }
}

.dropdown-menu .grid-container {
  height: 400px;
  margin-bottom: 35px;
  overflow: hidden;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -ms-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.metrics-opts {
  max-width: 440px !important;
}
.message-container {
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
}
.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
</style>
